import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const title = "קטע מספר 10: מנחל עוז לאנדרטת 'החץ השחור'";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <div>
          <p>
            <strong>אורך הקטע: 8.7 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה &#8211; בינונית</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            פונים ימינה לכביש 35, ורצים לאורכו עד לפניה שמאלה (צפונה), ק&quot;מ
            1.4 בדרך התוחמת חורשת אקליפטוסים ממערב. פונים שמאלה וממשיכים בדרך
            האספלט עד לקצה החורשה (פינה צפונית של החורשה). במפגש דרכים פונים
            ימינה כשהחורשה מימין עד לפניה שמאלה בסמוך לבית העלמין של כפר עזה.
            רצים לכיוון הקיבוץ, נכנסים בשער הצדדי ופונים ימינה לאורך גדר הקיבוץ
            כ- 2 ק&quot;מ עד לשער אחורי המוביל לשדות. ממשיכים בדרך הראשית
            ומתעלמים מהפניות עד לצמת T לאחר חציית נחל מפלסים. בצמת פונים שמאלה
            עד למפגש עם דרך לבנה מימין למרגלות 'תל מפלסים' המזדקר מעל השדה.
            עולים בדרך הלבנה בכיוון צפון מזרח עד למפגש עם דרך עפר המקבילה לכביש
            232. פונים שמאלה (צפונה) במקביל לכביש עד לאנדרטת 'חץ שחור'.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: שתי מגמות עליה
          </p>
          <p>
            <strong>מסלול</strong>: כביש ראשי (יהיה סגור לרכבים למעט צבא), דרך
            אספלט, דרך לבנה ודרך עפר.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות לרכבים הנוסעים בכניסה לקיבוץ כפר עזה
          </p>
          <p>
            <strong>תחנת החלפה</strong> : בחורשת ארהל'ה צוקרמן בצמוד לאנדרטת
            'החץ השחור'
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=scxrsslzyfkkstzt"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
